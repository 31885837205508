import React from 'react';
import MuiLink from '@material-ui/core/Link';
import styled from 'styled-components';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { formatDistanceToNow } from 'date-fns';

const ListingCard = styled.div`
  position: relative;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  max-width: 350px;
  margin: 10px 10px;
  @media (max-width: 450px) {
    margin: 10px 0px;
    margin-right: 10px;
  }
`;
const ImageSection = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;
`;
const StyledImg = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center left;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
`;
const TagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 20px;
`;

const AdTitle = styled.h5`
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  line-height: 1.3;
  a {
    color: #333;
    outline: none;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    transition: all 0.1s ease-in-out;
    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
const AdDescription = styled.p`
  line-height: 1.4;
`;
const Tags = styled.p``;
const SEODescription = styled.p`
  position: absolute;
  visibility: hidden;
`;
const StyledCardActions = styled(CardActions)`
  display: flex !important;
  justify-content: space-between;
`;

interface IProps {
  blogData: any;
  //   isWantedListing?: boolean;
  className?: string;
}
const BlogCard = ({ blogData, className }: IProps) => {
  const { permalink, post_title, post_date, post_excerpt, thumbnail, categories } = blogData;

  return (
    <ListingCard className={className}>
      <a href={permalink}>
        <ImageSection>
          <picture>
            <StyledImg
              srcSet={thumbnail.srcset}
              alt={post_title}
              sizes="(max-width: 450px) 300px,(min-width:450px) 300px,500px"
            />
          </picture>
          <noscript>
            <img src={thumbnail.src} alt={post_title} />
          </noscript>
        </ImageSection>
      </a>
      <TagsWrapper>
        <Tags>
          {categories.map((category: any, index: number) => {
            if (index !== categories.length - 1)
              return category.name.split('&amp;').join('&').concat(', ');
            return category.name.split('&amp;').join('&');
          })}
        </Tags>
      </TagsWrapper>
      <Divider light />
      <Content>
        <AdTitle>
          <MuiLink href={permalink}>{post_title}</MuiLink>
        </AdTitle>
        <AdDescription>{post_excerpt.slice(0, 70).concat('.....')}</AdDescription>
        <SEODescription>{post_excerpt}</SEODescription>
      </Content>
      <Divider light />
      <StyledCardActions>
        <MuiLink href={permalink}>
          <Button color="primary">Continue reading</Button>
        </MuiLink>
        <p>
          {formatDistanceToNow(new Date(post_date.split(' ')[0]), {
            addSuffix: true,
          })}
        </p>
      </StyledCardActions>
    </ListingCard>
  );
};
BlogCard.defaultProps = {
  className: '',
};
export default BlogCard;
