import useSwr from 'swr';
import config from 'src/config';
import client from 'src/utils/axios';
import { useEffect, useState } from 'react';

const fetcher = async (url: string) => {
  const result = await client.get(url);
  return result.data.data;
};

const useBlog = (SSRBlogs?: any) => {
  const [perPage, setPerPage] = useState(9);
  const [blogs, setBlogs] = useState<any[] | null>(SSRBlogs || null);

  const apiUrl = `${config.blogURL}${config.blogAPIKey}/posts?per_page=${perPage}&page=1`;
  const { data } = useSwr(apiUrl, fetcher);

  useEffect(() => {
    if (perPage > 9 && data !== undefined) {
      setBlogs(data);
    }
  }, [SSRBlogs, perPage, data]);

  if (!SSRBlogs) {
    return {
      blogs: data,
      isLoading: !data,
      perPage,
      setPerPage,
    };
  }

  return {
    blogs,
    isLoading: !data,
    perPage,
    setPerPage,
  };
};
export default useBlog;
